import React from "react";
import "./App.css";
import {
  Container,
  Image,
  ListGroup,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import L from "leaflet";
import {
  LayersControl,
  Map,
  Marker,
  Popup,
  ScaleControl,
  TileLayer,
} from "react-leaflet";
import Control from "react-leaflet-control";
import {
  FaTimes,
  FaCog,
  FaSave,
  FaArrowLeft,
  FaExpandArrowsAlt,
} from "react-icons/fa";
import { createXmlString } from "./gpx";

const urlPlaces =
  "https://blog-map-client-app.s3-eu-west-1.amazonaws.com/places.json";
const urlCategories =
  "https://blog-map-client-app.s3-eu-west-1.amazonaws.com/categories.json";

const iconGreen = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const iconBlue = new L.Icon({
  iconUrl:
    "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

class App extends React.Component {
  state = {
    places: [],
    categories: [],
    showPopup: false,
    selectedFilters: [],
    tmpSelectedFilters: [],
    bounds: null,
  };
  timerMapChange = null;

  componentDidMount() {
    this.fetchPlaces();
    this.fetchCategories();
  }

  fetchPlaces = async () => {
    const res = await fetch(urlPlaces);
    const data = await res.json();
    this.setState({ places: data });
  };
  fetchCategories = async () => {
    const res = await fetch(urlCategories);
    const data = await res.json();
    this.setState({ categories: data });
  };

  onMapChange = (e) => {
    if (this.timerMapChange) {
      clearTimeout(this.timerMapChange);
    }
    this.timerMapChange = setTimeout(this.onMapChangeBuffered, 1000, e);
  };

  onMapChangeBuffered = (e) => {
    let { target } = e;
    if (target._map) {
      // if coming from TileLayer onLoad (map onLoad not firing at all)
      target = target._map;
    }
    this.setState({ bounds: target.getBounds() });
  };

  handleFilterChange = (e) => {
    let { tmpSelectedFilters } = this.state;
    if (tmpSelectedFilters[e.target.name]) {
      delete tmpSelectedFilters[e.target.name];
    } else {
      tmpSelectedFilters[e.target.name] = 1;
    }
    this.setState({ tmpSelectedFilters });
  };

  openFilters = () => {
    let { selectedFilters, tmpSelectedFilters } = this.state;
    tmpSelectedFilters = { ...selectedFilters };
    this.setState({ tmpSelectedFilters, showPopup: true });
  };

  applyFilters = () => {
    let { selectedFilters, places, tmpSelectedFilters } = this.state;
    selectedFilters = { ...tmpSelectedFilters };
    for (let i = 0; i < places.length; i++) {
      places[i].isHiddenByFilters = false;
      let allCategoriesMatched = true;
      for (let category of Object.keys(selectedFilters)) {
        if (!places[i].categories.includes(category)) {
          allCategoriesMatched = false;
        }
      }
      places[i].isHiddenByFilters = !allCategoriesMatched;
    }
    this.setState({ places, showPopup: false, selectedFilters });
  };

  resetFilters = () => {
    this.setState(
      { selectedFilters: [], tmpSelectedFilters: [] },
      this.applyFilters
    );
  };

  downloadGps = () => {
    const xml = createXmlString(this.state.places);
    const url = "data:text/json;charset=utf-8," + xml;
    const link = document.createElement("a");
    link.download = `waypoints.gpx`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
  };

  render() {
    return (
      <Container>
        <Modal
          size="xl"
          show={this.state.showPopup}
          onHide={() => {
            this.setState({ showPopup: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Филтър по категории</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="category-list">
              <Row>
                {Object.keys(this.state.categories)
                  .sort()
                  .map((item, idx) => {
                    return (
                      <Col xs={6} lg={3} key={idx}>
                        <Form.Check
                          inline
                          id={"category-" + idx}
                          label={
                            item + " (" + this.state.categories[item] + ")"
                          }
                          name={item}
                          onChange={this.handleFilterChange}
                          defaultChecked={this.state.tmpSelectedFilters[item]}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.applyFilters}>
              Филтрирай
            </Button>
          </Modal.Footer>
        </Modal>

        <Map
          center={[42.48112, 25.48645]}
          zoom={10}
          onZoomend={this.onMapChange}
          onMoveend={this.onMapChange}
          onLoad={this.onMapChange}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="OpenStreetMap" checked={true}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                onLoad={this.onMapChange}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="BGMountains">
              <TileLayer
                url="https://bgmtile.kade.si/{z}/{x}/{y}.png"
                attribution="Map data &copy; <a href='http://kade.si'>BGMountains</a>, <a href='http://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-NC-SA 2.5"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="ESRI">
              <TileLayer url="https://clarity.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
            </LayersControl.BaseLayer>
          </LayersControl>
          <ScaleControl />

          <Control position="topleft">
            {window.self === window.top ? (
              <Button variant="primary" href="http://blog.ivanatora.info/map">
                <FaArrowLeft />
              </Button>
            ) : (
              <Button
                variant="primary"
                href="http://blog-map.ivanatora.info"
                target="_top"
              >
                <FaExpandArrowsAlt />
              </Button>
            )}
            <Button variant="primary" onClick={this.openFilters}>
              <FaCog />
            </Button>{" "}
            {Object.keys(this.state.selectedFilters).length > 0 && (
              <Button variant="danger" onClick={this.resetFilters}>
                <FaTimes />
              </Button>
            )}
            <Button variant="success" onClick={this.downloadGps}>
              <FaSave />
            </Button>
          </Control>

          {this.state.places
            .filter((item) => !item.isHiddenByFilters)
            // TODO: Can we make this filter work? Enabling, introduces funky behavior to popups and map pan
            // .filter((item) => {
            //   return (
            //     this.state.bounds &&
            //     this.state.bounds.contains(L.latLng(item.lat, item.lon))
            //   );
            // })
            .map((item, idx) => {
              return (
                <Marker
                  key={idx}
                  position={[item.lat, item.lon]}
                  icon={item.is_visited ? iconGreen : iconBlue}
                >
                  <Popup autoPan={false}>
                    <h3>{item.name}</h3>
                    {item.img && (
                      <div className="images">
                        <Image src={item.img} thumbnail />
                      </div>
                    )}
                    <ListGroup className="blogs">
                      {item.urls.map((item, idx) => {
                        return (
                          <a
                            key={idx}
                            href={item.href}
                            className="list-group-item list-group-item-action"
                            target="_blank"
                            rel="noopener noreferrer"
                            dangerouslySetInnerHTML={{ __html: item.title }}
                          />
                        );
                      })}
                    </ListGroup>
                  </Popup>
                </Marker>
              );
            })}
        </Map>
      </Container>
    );
  }
}

export default App;
