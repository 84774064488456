export const createXmlString = (places) => {
  let result =
    "<?xml version='1.0' encoding='UTF-8' standalone='yes' ?>" +
    '<gpx version="1.1" xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">';

  for (let place of places) {
    result += `<wpt lat="${place.lat}" lon="${place.lon}">
    <name>${place.name}</name>
    </wpt>`;
  }

  result += "</gpx>";
  return result;
};
